import styled from "styled-components";

import { Color, FontWeight } from "../theme";
import { breakpoints } from "../UIGlobals";
import { Button as ButtonBase } from "../UIElements";

export const LogoImg = styled.img`
  display: block;
  width: 154px;
  height: auto;
  margin-top: 50px;
`;

export const Button = styled(ButtonBase)`
  &:disabled {
    background-color: ${Color.dirtyWhite};
    &:hover {
      cursor: default;
    }
  }
`;

export const Wrapper = styled.div`
  width: 66.6%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (${breakpoints().maxL}) {
    padding: 100px 0 50px;
  }
  @media (${breakpoints().maxS}) {
    width: 100%;
    max-width: 100%;
    margin: 30px 25px;
  }
`;

export const Header = styled.div`
  font-size: 2.2rem;
  font-weight: ${FontWeight.Light};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: ${Color.black};
  margin-bottom: 50px;
  margin-top: 25px;
  max-width: 380px;
`;

export const MoreAction = styled.div`
  font-size: 1.3rem;
  line-height: 1.54;
  text-align: center;
  display: block;
  margin-top: 30px;
  color: ${Color.gray2};
`;

export const Link = styled.a`
  display: inline-block;
  color: ${Color.almostBlack};
  text-decoration: none;
  margin-right: 15px;
`;

export const Error = styled.span`
  font-size: 1.3rem;
  line-height: 1.54;
  text-align: center;
  color: ${Color.error};
  display: block;
  margin-bottom: 30px;
  max-width: 380px;
`;

export const ErrorLink = styled.a`
  color: ${Color.error};
`;

export const InputWrapper = styled.div`
  margin-bottom: 45px;
  max-width: 380px;
`;

export const MiddleWrapper = styled.div`
  @media (${breakpoints().maxS}) {
    padding-top: 60px;
  }
`;

export const ImageColumn = styled.div`
  width: 33.3%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Color.almostWhite};
  overflow: hidden;
`;

export const BgImage = styled.img`
  max-width: 85%;
  height: auto;
`;
